import React from 'react';
import PropTypes from 'prop-types';
import withVertical from '@clearscore-group/ui.external-cs-auth.vertical';
import RainbowContainer from '@clearscore/rainbow.container';

import reducer from './lib/reducer';
import epics from './lib/epics';
import appBridgeTypes from './lib/app-bridge-types';
import SignUpForm from './components/signup-form';

const SignUp = ({ routeProps: { newMarketsEnabled } }) => (
    <RainbowContainer size={RainbowContainer.Size.SMALL}>
        <SignUpForm newMarketsEnabled={newMarketsEnabled} />
    </RainbowContainer>
);

SignUp.propTypes = {
    routeProps: PropTypes.shape({
        newMarketsEnabled: PropTypes.bool,
    }).isRequired,
};

export default withVertical(SignUp, {
    name: 'sign-up',
    reducer,
    epics,
    appBridgeTypes,
});
