import createValidator from '@clearscore/validation.create';
import { isRequiredValidation } from '@clearscore/validation.is-required';
import { isEmailValidation } from '@clearscore/validation.is-email';

const validatorFactory = (isRequiredError, isEmailError) =>
    createValidator({
        email: [isRequiredValidation(isRequiredError), isEmailValidation(isEmailError)],
    });

export default validatorFactory;
