import i18next from 'i18next';
import { GB } from '@clearscore/config.i18n';

import marketConfig from './market-config';
import { shouldShowLanguageSelector } from '../../../lib/helpers';

const getLanguagesByMarket = (selectedMarket: string): Record<string, { text: string; value: string }> =>
    selectedMarket ? marketConfig[selectedMarket].languages : {};

const isMarketSupported = (market: string): boolean => {
    const supportedMarkets = Object.keys(marketConfig);

    return supportedMarkets.includes(market);
};

const determineCurrentMarket = (): string => {
    const i18nextDetectedLanguage = i18next.language;
    const i18nextDetectedMarket = i18nextDetectedLanguage.split('-')[1];

    if (!i18nextDetectedMarket) return GB;
    if (!isMarketSupported(i18nextDetectedMarket)) return GB;

    return i18nextDetectedMarket;
};

const handleMarketChange =
    ({ onChange }: { onChange: (event: Event) => void }) =>
    (event: Event): void => {
        const target = event.target as HTMLInputElement;
        const market = target.value;
        const options = Object.values(getLanguagesByMarket(market));
        const selectedLanguage = options[0].value;

        i18next.changeLanguage(selectedLanguage);

        onChange(event);
    };

const handleLanguageChange =
    ({ onChange }: { onChange: (event: Event) => void }) =>
    (event: Event): void => {
        const target = event.target as HTMLInputElement;
        const language = target.value;

        i18next.changeLanguage(language);

        onChange(event);
    };

const handleLanguages = (): {
    handleLanguageChange: ({ onChange }: { onChange: (event: Event) => void }) => (event: Event) => void;
    handleMarketChange: ({ onChange }: { onChange: (event: Event) => void }) => (event: Event) => void;
    currentMarket: string;
    showLanguageSelector: boolean;
    languageOptions: { text: string; value: string }[];
} => {
    const currentMarket = determineCurrentMarket();
    const languagesByMarket = getLanguagesByMarket(currentMarket);
    const languageOptions = Object.values(languagesByMarket);
    const showLanguageSelector = shouldShowLanguageSelector(languageOptions);

    return {
        languageOptions,
        handleMarketChange,
        currentMarket,
        handleLanguageChange,
        showLanguageSelector,
    };
};

export default handleLanguages;
