import { EN_GB } from '@clearscore/config.i18n';
import { types as prospectTypes } from '@clearscore/redux.prospect';
import { constants as alertConstants } from '@clearscore/redux.alerts';
import i18next from 'i18next';

import { PAGE_NAMESPACE } from './constants';

const alertWithMessage = (message) => ({
    message,
    alertType: alertConstants.ERROR,
    isDismissible: true,
});

const withRequestMetaLocale =
    (next) =>
    ({ locale }, rest) =>
        next({ ...rest, locale: locale || EN_GB });

export default {
    [prospectTypes.PROSPECT_CREATE_ERROR]: withRequestMetaLocale(({ locale }) => {
        const t = i18next.getFixedT(locale, PAGE_NAMESPACE);
        return alertWithMessage(t('errorMessage'));
    }),
};
