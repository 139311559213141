import { flatMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { push } from 'connected-react-router';
import { types as prospectTypes } from '@clearscore/redux.prospect';

/**
 * @description Middleware to handle sign up
 * @param {Observable<Action>} action$ Observable
 * @param {Observable<State>} state$ Observable
 * @returns {Observable<Action>} Observable
 */
const signUpEpic = (action$) =>
    action$.pipe(
        ofType(prospectTypes.PROSPECT_CREATE_SUCCESS),
        flatMap(({ payload, requestMeta: { market } }) => {
            const { prospectId, prospectHasAccount, allowedUser, email } = payload || {};

            const userHasAlreadyRegistered = prospectHasAccount || !allowedUser;

            if (userHasAlreadyRegistered) {
                const location = {
                    pathname: `/login`,
                    state: { registeredEmailValue: email },
                };
                return of(push(location));
            }

            return of(push(`/step1/${market}/${prospectId}`));
        }),
    );

export default signUpEpic;
