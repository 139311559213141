import i18next from 'i18next';
import { EN_GB, FR_CA } from '@clearscore/config.i18n';

import { PAGE_NAMESPACE } from './lib/constants';
import pageGb from './copy/pages/signup/en-gb/copy.json';
import pageFr from './copy/pages/signup/fr-ca/copy.fr-ca.json';
import { isMultilingualFeatureActive } from './lib/helpers';

export { default as Component } from './sign-up';

i18next.addResourceBundle(EN_GB, PAGE_NAMESPACE, pageGb);

if (isMultilingualFeatureActive()) {
    i18next.addResourceBundle(FR_CA, PAGE_NAMESPACE, pageFr);
}
