/* eslint-disable jsx-a11y/tabindex-no-positive */
import React, { useState } from 'react';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import normalizeEmail from '@clearscore/normalize.email';
import Button from '@clearscore/rainbow.button';
import TextInput from '@clearscore/rainbow.text-input';
import Text from '@clearscore/rainbow.text';
import FormLayout from '@clearscore/rainbow.form-layout';
import Label from '@clearscore/rainbow.label';
import MarketSwitcher from '@clearscore/rainbow.market-switcher';
import Popover from '@clearscore/rainbow.popover';
import Dropdown from '@clearscore/rainbow.dropdown';
import suggestEmail from '@clearscore/helpers.suggest-email';
import SuggestedEmailPopup from '@clearscore/shared.suggested-email-popup';

import marketConfig from './lib/market-config';
import handleLanguages from './lib/handle-languages';
import validate from './lib/validate';
import styles from './sign-up-form.module.css';
import { PAGE_NAMESPACE } from '../../lib/constants';

const useHandleEmailSuggestion = (isLoading) => {
    const [showSuggestion, setShowSuggestion] = useState(false);
    const [emailSuggestion, setEmailSuggestion] = useState(null);
    const [initialValues, setInitialValues] = useState({});

    const handlePopupClose = () => {
        setShowSuggestion(false);
    };

    /**
     * Remember the email address, ready for a rerender
     * caused by changing market/language.
     */
    const rememberEmailAddress = (emailRef, emailValue) => {
        // eslint-disable-next-line no-param-reassign
        emailRef.current = emailValue;
    };

    const handlePopupConfirm = (emailRef) => () => {
        rememberEmailAddress(emailRef, emailSuggestion);
        setInitialValues({ email: emailSuggestion });
        setShowSuggestion(false);
    };

    const handleEmailChange =
        ({ onChange }, emailRef) =>
        ({ target: { value: email } }) => {
            const suggestedEmail = suggestEmail({ email });
            if (!isLoading && suggestedEmail && suggestedEmail !== email) {
                setShowSuggestion(true);
                setEmailSuggestion(suggestedEmail);
            } else if (showSuggestion) {
                setShowSuggestion(false);
            }

            rememberEmailAddress(emailRef, email);

            onChange(email);
        };

    return {
        initialValues,
        handlePopupClose,
        handlePopupConfirm,
        handleEmailChange,
        showSuggestion,
        emailSuggestion,
    };
};

const SignUpForm = ({ handleSignUp, isLoading, newMarketsEnabled }) => {
    const { t } = useTranslation(PAGE_NAMESPACE);

    const { initialValues, handlePopupClose, handlePopupConfirm, handleEmailChange, showSuggestion, emailSuggestion } =
        useHandleEmailSuggestion(isLoading);

    // remember the email address, ready for a rerender caused by changing market/language
    // supper important for prod checks/pingdom that email address is not lost when other form values change!
    const email = React.useRef(initialValues.email);

    const { languageOptions, handleMarketChange, currentMarket, handleLanguageChange, showLanguageSelector } =
        handleLanguages();

    const markets = Object.values(marketConfig).filter(
        (configOption) => newMarketsEnabled || !configOption.isNewMarket,
    );

    const signupFormCopy = t('signupForm');

    return (
        <Form
            validate={validate(signupFormCopy.isRequiredError, signupFormCopy.isEmailError)}
            initialValues={{
                ...initialValues,
                email: email.current,
                market: currentMarket,
                language: i18next.language,
            }}
            onSubmit={handleSignUp}
            render={({ handleSubmit, invalid, values }) => (
                <form className={styles.signUpForm} onSubmit={handleSubmit}>
                    <Text.H1>{signupFormCopy.mainTitle}</Text.H1>
                    <FormLayout>
                        <FormLayout.Fieldset>
                            <FormLayout.Item>
                                <Label htmlFor="email">{signupFormCopy.email.label}</Label>
                                <Field name="email" type="email" parse={normalizeEmail}>
                                    {({ input, meta }) => (
                                        <TextInput
                                            id="email"
                                            name="email"
                                            tabIndex="1"
                                            autoFocus
                                            isLightBg
                                            value={input.value}
                                            placeholder={signupFormCopy.email.placeholder}
                                            onBlur={input.onBlur}
                                            onChange={handleEmailChange(input, email)}
                                            isValid={meta.touched && meta.valid}
                                            isInvalid={meta.touched && meta.invalid}
                                            errorMessage={(meta.touched && meta.error) || ''}
                                            dataId="email-input"
                                        />
                                    )}
                                </Field>
                                {marketConfig[values.market].emailSuggestion && showSuggestion ? (
                                    <div className={styles.emailSuggestion}>
                                        <div className={styles.emailSuggestionHolder}>
                                            <Popover
                                                text={
                                                    <SuggestedEmailPopup
                                                        suggestion={emailSuggestion}
                                                        confirm={handlePopupConfirm(email)}
                                                        close={handlePopupClose}
                                                        language={signupFormCopy}
                                                    />
                                                }
                                                placement={Popover.Placement.BOTTOM}
                                                theme={Popover.Theme.LIGHT}
                                                alignment={Popover.Alignment.LEFT}
                                            />
                                        </div>
                                    </div>
                                ) : null}
                            </FormLayout.Item>
                            <FormLayout.Item>
                                <Label htmlFor="market">{signupFormCopy.marketLabel}</Label>
                                <Field name="market" type="select">
                                    {({ input }) => (
                                        <MarketSwitcher
                                            options={markets}
                                            name="market"
                                            {...input}
                                            isLightBg
                                            tabIndex="2"
                                            onChange={handleMarketChange(input)}
                                        />
                                    )}
                                </Field>
                            </FormLayout.Item>
                            {showLanguageSelector ? (
                                <FormLayout.Item>
                                    <Label htmlFor="language">{signupFormCopy.languageLabel}</Label>
                                    <Field name="language" type="select">
                                        {({ input }) => (
                                            <Dropdown
                                                options={languageOptions}
                                                name="language"
                                                {...input}
                                                tabIndex="3"
                                                value={i18next.language}
                                                onChange={handleLanguageChange(input)}
                                            />
                                        )}
                                    </Field>
                                </FormLayout.Item>
                            ) : null}

                            <FormLayout.Item>
                                <Button
                                    htmlType="submit"
                                    dataId="submit"
                                    tabIndex="4"
                                    isResponsive
                                    type={Button.types.PRIMARY}
                                    size={Button.sizes.LARGE}
                                    isLoading={isLoading}
                                    isDisabled={isLoading || invalid}
                                >
                                    {signupFormCopy.submit.label}
                                </Button>
                            </FormLayout.Item>
                        </FormLayout.Fieldset>
                    </FormLayout>

                    <div className={styles.linksWrapper}>
                        <Text.Body2>
                            {signupFormCopy.alreadyRegistered}{' '}
                            <Text.Link1 dataId="already-registered-link" href="/login" tag={Text.tags.SPAN}>
                                {signupFormCopy.loginHere}
                            </Text.Link1>
                        </Text.Body2>
                    </div>
                </form>
            )}
        />
    );
};

SignUpForm.propTypes = {
    handleSignUp: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    newMarketsEnabled: PropTypes.bool,
};

SignUpForm.defaultProps = {
    newMarketsEnabled: false,
};

export default SignUpForm;
