import { GB, ZA, AU, EN_ZA, EN_AU, EN_GB, EN_CA, CA, FR_CA } from '@clearscore/config.i18n';

import UKFlagPNG from '../assets/uk-flag.png';
import ZAFlagPNG from '../assets/za-flag.png';
import AUFlagPNG from '../assets/au-flag.png';

interface Market {
    text: string;
    value: string;
    key: string;
    imageSrc: unknown;
    emailSuggestion: boolean;
    languages: Record<
        string,
        {
            text: string;
            value: string;
        }
    >;
}

type MarketConfig = Record<string, Market>;

export default {
    [GB]: {
        text: 'United Kingdom',
        value: GB,
        key: GB,
        imageSrc: UKFlagPNG,
        emailSuggestion: true,
        languages: {
            EN_GB: {
                text: 'English (British)',
                value: EN_GB,
            },
        },
    },

    [ZA]: {
        text: 'South Africa',
        value: ZA,
        key: ZA,
        imageSrc: ZAFlagPNG,
        emailSuggestion: false,
        languages: {
            EN_ZA: {
                text: 'English (United Kingdom)',
                value: EN_ZA,
            },
        },
    },

    [AU]: {
        text: 'Australia',
        value: AU,
        key: AU,
        imageSrc: AUFlagPNG,
        isNewMarket: true,
        emailSuggestion: true,
        languages: {
            EN_AU: {
                text: 'English (Australia)',
                value: EN_AU,
            },
        },
    },

    [CA]: {
        text: 'Canada',
        value: CA,
        key: CA,
        imageSrc: '',
        isNewMarket: true,
        emailSuggestion: true,
        languages: {
            EN_CA: {
                text: 'English (Canada)',
                value: EN_CA,
            },
            FR_CA: {
                text: 'Français (Canada)',
                value: FR_CA,
            },
        },
    },
} as MarketConfig;
