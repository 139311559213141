/* eslint-disable jsx-a11y/tabindex-no-positive */
import React, { useRef, useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import Button from '@clearscore/rainbow.button';
import Text from '@clearscore/rainbow.text';

import styles from './suggested-email-popup.module.css';

const emailTokeniser = (email) => email.split('@');
const OverflowFragment = <Fragment>&hellip;</Fragment>;

const SuggestedEmailPopup = ({ suggestion, confirm, close, language, isOverflown }) => {
    const [address, domain] = emailTokeniser(suggestion);
    const addressOuterContainer = useRef(null);
    const addressContainer = useRef(null);
    const [overflow, setOverflow] = useState(null);

    useEffect(() => {
        const container = addressOuterContainer.current;
        const child = addressContainer.current;
        setOverflow(isOverflown(container, child) && OverflowFragment);
    }, [isOverflown, suggestion]);

    return (
        <div className={styles.emailPopupContent} data-id="email-popup">
            <div className={styles.suggestionContainer} data-id="email-popup-suggestion">
                <Text.Body2 tag={Text.tags.SPAN}>{language.suggestion}&nbsp;</Text.Body2>
                <Text.Body1 weight={Text.weights.BOLD} tag={Text.tags.SPAN}>
                    <span className={styles.emailSuggestion}>
                        <span ref={addressOuterContainer}>
                            <span ref={addressContainer}>{address}</span>
                        </span>
                        <span>
                            {overflow}@{domain}?
                        </span>
                    </span>
                </Text.Body1>
            </div>
            <div className={styles.emailPopupCTA}>
                <Button
                    dataId="confirm-popup"
                    type={Button.types.SECONDARY}
                    size={Button.sizes.SMALL}
                    theme={Button.themes.DARK}
                    isNarrow
                    isLoading={false}
                    isWide={false}
                    onClick={confirm}
                >
                    {language.emailConfirm}
                </Button>
                <Button
                    dataId="close-popup"
                    type={Button.types.SECONDARY}
                    sizes={Button.sizes.SMALL}
                    theme={Button.themes.DARK}
                    isNarrow
                    isLoading={false}
                    isWide={false}
                    onClick={close}
                >
                    {language.emailDeny}
                </Button>
            </div>
        </div>
    );
};

SuggestedEmailPopup.defaultProps = {
    isOverflown: (container, child) => container.offsetWidth < child.offsetWidth,
};

SuggestedEmailPopup.propTypes = {
    suggestion: PropTypes.string.isRequired,
    confirm: PropTypes.func.isRequired,
    close: PropTypes.func.isRequired,
    language: PropTypes.object.isRequired,
    isOverflown: PropTypes.func,
};

export default SuggestedEmailPopup;
