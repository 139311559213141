import connect from '@clearscore/redux.connect';
import { selectors as prospectSelectors, actions as prospectActions } from '@clearscore/redux.prospect';
import formStatus from '@clearscore/helpers.form-status';
import { getAnonymousId } from '@clearscore/helpers.segment';
import { getPublisherCookie } from '@clearscore/helpers.cookies';

import Component from './sign-up-form';

const mapState = (state) => {
    const signUpFormStatus = formStatus(prospectSelectors.getPredicate(state).fetch);
    const isLoading = signUpFormStatus.submitting || signUpFormStatus.submitSucceeded;

    return {
        isLoading,
    };
};

const getURLParams = () => {
    const urlParams = new URLSearchParams(window.location.search);
    let { publisherId, transactionId, campaignId } = getPublisherCookie();
    publisherId = publisherId || urlParams.get('utm_source');
    transactionId = transactionId || urlParams.get('utm_medium');
    campaignId = campaignId || urlParams.get('utm_campaign');

    return {
        ...(publisherId && { publisherId }),
        ...(transactionId && { transactionId }),
        ...(campaignId && { campaignId }),
        anonymousId: getAnonymousId(),
    };
};

const mapDispatch = (dispatch) => ({
    handleSignUp: ({ email, market }) =>
        dispatch(
            prospectActions.createProspect(
                {
                    email,
                    ...getURLParams(),
                },
                market,
            ),
        ),
});

export default connect(mapState, mapDispatch, { useRouter: true })(Component);
