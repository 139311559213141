import { getCookie } from '@clearscore/helpers.cookies';

export const isMultilingualFeatureActive = (): boolean => {
    const experimentalFeatures = getCookie('features');
    return !!experimentalFeatures?.multilingual;
};

export const shouldShowLanguageSelector = (languageOptions: { text: string; value: string }[]): boolean => {
    if (!isMultilingualFeatureActive()) return false;
    return languageOptions.length > 1;
};
